@import '../../shared.scss';

$wrap-width: 1300px;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  background-color: #fff;
  border-top: 1px #E4E4E4 solid;
  z-index: 100;

  .wrap {
    width: $wrap-width;
    max-width: $wrap-width;
    display: flex;
    align-items: center;
    position: relative;
  }

  @media (max-width: ($wrap-width + 30px)) {
    .wrap {
      width: 100%;
      padding: 0 30px;
    }
  }

  @media (max-width: 520px) {
    .wrap {
      padding: 0 10px;
    }
  }

  .play {
    color: $palette-primary-main;
    cursor: pointer;
    font-size: 31px !important;
  }

  .close {
    font-size: 40px !important;
    color: #222222;
    background: transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: -35px;
    right: 10px;

    // @media (max-width: $layout-breakpoint-max) {
    //   right: 15px;
    // }
  }

  .progress {
    height: 8px;
    max-width: 412px;
  }

  .progress-thumb {
    display: none;
  }

  .progress-track {
    height: 8px;
    border-radius: 4px;
    background: linear-gradient(90deg, #FFFFFF 0%, #FF651A 100%);
    border: none;
  }

  .progress-rail {
    height: 8px;
    border-radius: 4px;
    background: #CDCDCD;
  }

  .instance {
    display: none;
  }

  .image {
    width: 60px;
    height: 60px;
    min-width: 60px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }

  .title {
    max-width: 180px;
    width: 180px;
    margin-right: 10px;
    line-height: 22px;
    font-size: 16px;
    font-family: "Futura Medium", Futura;
    font-weight: 500;
    color: #454545;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap
  }

  .tag {
    // margin-top: 4px;
    max-width: 153px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-family: "Futura Medium", Futura;
    font-weight: 500;
    color: #454545;
  }

  .cell {
    margin-left: 27px;
    margin-right: 24px;
  }

  .time {
    min-width: 100px;
    height: 16px;
    font-size: 14px;
    // font-family: OpenSans-Semibold, OpenSans;
    font-weight: 600;
    color: #cdcdcd;
    line-height: 16px;
    text-align: center;
  }

  .time-all {
    color: #888888;
  }

  .volume {
    color: #FF651A;
    font-size: 31px !important;
    cursor: pointer;
  }

  @media (max-width: $layout-breakpoint-min) {
    .progress {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .time {
      display: none;
    }
  }

  @media (max-width: 350px) {
    .title {
      width: auto;
    }
    .info {
      flex: 1;
    }
  }
}
