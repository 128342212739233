

.root {
  width: 450px;
  // @media (max-width: 670px) {
  //   width: calc(100vw - 20px);
  // }
  z-index: 173;
  border-radius: 4px;
  box-shadow: 0px 5px 12px 0px rgba(64, 64, 64, 1);
  overflow: hidden;
  background-color: #FFF;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  padding-bottom: 15px;

  .head {
    // display: flex;
    text-align: right;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;

    svg {
      cursor: pointer;
    }
  }

  .title {
    font-size: 30px;
    font-family: 'Futura Condensed ExtraBold', Futura;
    font-weight: normal;
    color: #222222;
    line-height: 39px;
    text-align: center;
  }

  .shareTypes {
    display: flex;
    width: 250px;
    margin: 20px auto;
    flex-direction: row;
    justify-content: space-between;

    svg {
      width: 50px;
      height: 50px;
    }

    .iconWrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      svg {
        width: 35px;
        height: 35px;
      }
      cursor: pointer;
    }

    .facebook {
      background-color: #FFF;
      color: #3C599B;
      svg {
        width: 60px;
        height: 60px;
      }
    }

    .twitter {
      background-color: #009FED;
      color: #FFF;
    }

    .reddit {
      background-color: #FF2700;
      color: #FFF;
    }
  }

  .copyText {
    font-size: 14px;
    font-family: 'Futura Condensed ExtraBold', Futura;
    font-weight: normal;
    color: #222222;
    line-height: 18px;
    width: 330px;
    margin: 15px auto;
  }
  .copyField {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    border: 1px solid #CCCCCC;
    width: 330px;
    height: 40px;
    margin: 0 auto;

    .link {
      color: #808080;
      transform: rotate(-45deg);
    }

    .linkWrap {
      width: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .input {
      flex: 1;
      color: #808080;
      font-size: 12px;
      font-family: 'Futura Medium', Futura;
      line-height: 17px;
    }

    .button {
      text-transform: none;
      width: 65px;
      height: 26px;
      background: #FF651A;
      border-radius: 6px;
      margin: 0 10px;
    }
  }

  .tips {
    height: 38px;
    width: 330px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    color: #56B13B;
    font-size: 12px;
    font-family: 'Futura Medium', Futura;
    font-weight: 400;
    line-height: 17px;
    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .divider {
    width: 330px;
    margin: 0 auto;
  }

  .info {
    width: 257px;
    margin: 5px auto;
    text-align: center;
    font-size: 14px;
    font-family: 'Futura Medium', Futura;
    font-weight: 400;
    color: #181C1F;
    line-height: 24px;
  }
  .copyField + .info {
    margin-top: 10px;
  }
}

.paper {
  height: 218px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px 12px 0px 0px;

  .drawer {
    // height: 218px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .title {
      height: 33px;
      font-size: 26px;
      font-family: 'Futura Condensed ExtraBold', Futura;
      font-weight: normal;
      color: #222222;
      line-height: 34px;
      text-align: center;
      margin-top: 25px;

    }

    .shareTypes {
      display: flex;
      width: 260px;
      margin: 20px auto;
      flex-direction: row;
      justify-content: space-between;

      .iconWrap {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        svg {
          width: 30px;
          height: 30px;
        }
        cursor: pointer;
      }
  
      .facebook {
        background-color: #FFF;
        color: #3C599B;
        svg {
          width: 48px;
          height: 48px;
        }
      }
  
      .twitter {
        background-color: #009FED;
        color: #FFF;
      }
  
      .reddit {
        background-color: #FF2700;
        color: #FFF;
      }

      .link {
        background-color: #56B13B;
        color: #FFF;
        transform: rotate(-45deg);
      }
    }

    .info {
      font-size: 14px;
      font-family: 'Futura Medium', Futura;
      font-weight: 600;
      color: #181C1F;
      line-height: 24px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.paper-snackbar {
  width: 130px;
}

.subscribe {
  width: 656px;
  margin: 10px auto;

  @media (max-width: 750px) {
    width: auto;
    padding: 0 15px;
  }

  .divider {
    color: #222222;
  }

  .shareTypes {
    position: relative;
    display: flex;
    width: 480px;
    margin: 20px auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
      
    @media (max-width: 750px) {
      max-width: 260px;
    }

    .iconWrap {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      svg {
        width: 23px;
        height: 23px;
      }
      cursor: pointer;
    }

    .facebook {
      background-color: #FFF;
      color: #3C599B;
      svg {
        width: 35px;
        height: 35px;
      }
    }

    .twitter {
      background-color: #009FED;
      color: #FFF;
    }

    .reddit {
      background-color: #FF2700;
      color: #FFF;
    }

    .link {
      background-color: #56B13B;
      color: #FFF;
      transform: rotate(-45deg);
      display: none;
      
      @media (max-width: 750px) {
        display: flex;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .copyField {
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      align-items: center;
      border: 1px solid #CCCCCC;
      width: 330px;
      height: 40px;
      margin: 0;

      .linkIcon {
        color: #808080;
        transform: rotate(-45deg);
        background-color: #FFF;
        display: block;
        width: 23px;
        height: 23px;
      }

      .linkWrap {
        width: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .input {
        flex: 1;
        color: #808080;
        font-size: 12px;
        font-family: 'Futura Medium', Futura;
        line-height: 17px;
      }

      .button {
        text-transform: none;
        width: 65px;
        height: 26px;
        background: #FF651A;
        border-radius: 6px;
        margin: 0 10px;
      }

      @media (max-width: 750px) {
        display: none;
      }
    }

    .tips {
      position: absolute;
      right: -135px;

      height: 40px;
      width: 125px;
      margin: 0;
      display: flex;
      align-items: center;
      color: #56B13B;
      font-size: 12px;
      font-family: 'Futura Medium', Futura;
      font-weight: 400;
      line-height: 17px;
      svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      
      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .info {
    font-size: 14px;
    font-family: 'Futura Medium', Futura;
    font-weight: 400;
    color: #181C1F;
    line-height: 24px;
    text-align: center;
    margin-bottom: -14px;

    @media (max-width: 750px) {
      display: none;
    }
  }

  .info2 {
    font-size: 14px;
    font-family: 'Futura Medium', Futura;
    font-weight: 400;
    color: #181C1F;
    line-height: 20px;
    text-align: center;
    margin-bottom: -14px;
    display: none;

    @media (max-width: 750px) {
      display: block;
    }
  }
}