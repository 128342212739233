@import '../../shared.scss';

.root {
  padding: 30px 40px 0 40px;
  // height: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;

  @media (max-width: 500px) {
    height: 270px;
  }

  .image {
    width: 626px;
    // height: 296px;
    box-shadow: 0px 5px 12px 0px #AAAAAA;
    border-radius: 14px;
    margin: 24px;
    z-index: 200;
    background-color: #AAAAAA;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 950px) {
      width: 900px;
      max-width: 900px;
      margin: 24px 40px;
    }
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    // height: 296px;
    @media (max-width: 950px) {
      display: none;
    }
  }

  h1 {
    margin: 20px 0;
    font-family: "Futura Condensed ExtraBold", Futura;
    font-weight: bold;
    color: #6F6F6F;
    line-height: 55px;
    text-align: left;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  p, .p {
    margin: 5px 0;
    font-family: "Futura Medium", Futura;
    font-weight: 500;
    color: #454545;
    line-height: 32px;
    text-align: left;
    flex: 1;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    // height: 192px;
    // max-height: 192px;
    overflow: hidden;
  }

  button {
    width: 185px;
    height: 55px;
    // background: #FF651A;
    box-shadow: 0px 5px 12px 0px #AAAAAA;
    border-radius: 28px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    font-family: "Futura Bold", Futura;
    /* line-height: 34px; */
    text-transform: none;
    margin-bottom: 5px;
    background: linear-gradient(35deg, #FFA240 0%, #FF651A 100%);
  }

  .icon {
    margin-left: 0;
  }

  @media (max-width: 500px) {
    padding: 0;

    .image {
      margin: 20px 20px;
    }
  }
}
