.header {
  height: 75px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;

  @media (max-width: 700px) {
    // height: 60px;
  }

  &.headerFix {
    position: fixed;
    background: rgba(34,34,34,0.5);
    // opacity: 0.5;
    z-index: 1000;
    display: block;
  }

  &.headerStatic {
    display: block;
  }

  &.open {
    background-color: #000;
    .signup {
      display: none;
    }
  }

  &.normal {
    background-color: #000;
  }

  // background: ;
  // background-color:rgba(34,34,34,0.2);

  .container {
    width: calc(100vw - 320px);
    margin: 0 auto;
    height: 75px;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      width: 80vw;
    }
    @media (max-width: 700px) {
      width: auto;
      // height: 60px;
      padding: 0 20px;
    }
  }

  .headerLeft {
    display: flex;
    flex-direction: row;
  }

  .logo {
    height: 100%;
    width: 100px;
    background-image: url('../../logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &.black {
      background-image: url('../../logo-black.svg');
    }
  }

  .menu {
    display: none;
    color: #FFF;
    margin-left: 15px;
    margin-top: 5px;
  }

  .close {
    font-size: 32px;
  }

  @media (max-width: 700px) {
    .menu {
      display: block;
    }
  }

  .actions {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    //margin-right: 64px;

    .login,.pricing {
      color: #FFF;
      text-transform: none;
      margin-right: 64px;
      font-size: 16px;
      font-family: 'Futura Medium', Futura;
      font-weight: 500;
      &.black {
        color: black;
        &:after {
          position: absolute;
          bottom: 4px;
          left: 8px;
          right: 8px;
          width: 0;
          content: "";
          color: transparent;
          background: black;
          height: 2px;
          transition: all .2s;
        }
        &:hover:after {
          color: black;
          width: calc(100% - 16px);
        }
      }

      &:after {
        position: absolute;
        bottom: 4px;
        left: 8px;
        right: 8px;
        width: 0;
        content: "";
        color: transparent;
        background: #FFF;
        height: 2px;
        transition: all .2s;
      }
      &:hover:after {
        color: #FFF;
        width: calc(100% - 16px);
      }
    }

    .signup {
      color: #FFF;
      text-transform: none;
      border-radius: 24px;
      border: 1px solid #FFFFFF;
      font-size: 16px;
      font-family: 'Futura Medium', Futura;
      font-weight: 500;
      &:hover {
        color: #000;
        background-color: #FFFFFF;
      }
      &.black {
        border: 1px solid black;
        color: black;
        &:hover {
          color: #FFF;
          background-color: black;
        }
      } 
    }

    @media (max-width: 700px) {
      .login,.pricing {
        display: none;
      }
    }
  }
}
.drawerRoot {
  top: 75px !important;
}
.drawerPaper {
  font-family: 'Futura Medium', Futura;
  width: 100vw;
  // background-color: #F2F2F2;
  background-color: #21282C !important;
  top: 75px !important;
  height: calc(100vh - 75px) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    height: 75px;
    width: 100%;
    background-image: url('../../logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000;
    display: none;
  }

  .linkRow {
    // height: 40px;
    a {
      padding: 30px 40px 0 40px;
      display: block;
      font-size: 18px;
      font-family: 'Futura Bold', Futura;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      cursor: pointer;
    }
    & + & {
      border-top: 1px solid #EDEDED;
    }
  }

  .drawerPaperFooter {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 80px;

    font-size: 12px;
    font-family: 'Futura Medium', Futura;
    font-weight: 400;
    color: #999999;
    line-height: 17px;

    .copyright {
      margin-bottom: 10px;
    }
    .policies {
      margin-bottom: 10px;
      a {
        color: #999999;
        text-decoration: none;
      }
      a + a {
        margin-left: 18px;
      }
    }
    .social {
      svg {
        color: #C1C2C3;
      }
      a + a {
        margin-left: 20px;
      }
    }
  }
  
}