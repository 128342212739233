

.root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  header {
    z-index: 100;
  }

  .seg {
    position: relative;
    height: calc(100vh - 100px);
    //min-width: 100vw;
    // min-height: 600px;
    background-color: #181818;
    display: flex;
    justify-content: center;
    // align-items: center;

    video {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      //z-index: -11;
    }

    .introduction {
      position: absolute;
      text-align: center;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #FFFEFE;
      font-weight: 500;
      h1 {
        margin: 0 0 40px;
        font-size: 96px;
        line-height: 128px;
      }
      p {
        margin: 0;
        width: 586px;
        font-size: 26px;
        line-height: 34px;
      }

      @media (max-width: 700px) {
        & {
          top: 230px;
          height: auto;
        }
        h1 {
          font-size: 64px;
          line-height: 70px;
          width: 298px;
          margin: 0 0 30px;
        }
        p {
          font-size: 15px;
          line-height: 20px;
          width: 328px;
        }
      }
    }

  }
  .sale {
    background-color: black;
    display: block;
    width:100%;
    cursor: pointer;
    //height: auto;
    height: 14.6vw;
    background-image: url("../../images/pc/sale.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 500px){
      background-image: url("../../images/mb/sale.jpg");
      background-size: cover;
      height: 66.8vw;
    }
  }
  .seg1 {
    position: relative;
    background-color: #FAF8ED;
    min-height: 570px;
    height: 570px;
    background-image: url("../../images/pc/seg-1-line-bak.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    overflow: hidden;

    .vocalnet {
      text-align: center;
      position: absolute;
      top: 72px;
      left: calc(50% + 315px + 153px);
      width: 320px;

      h2 {
        margin: 0 0 36px 0;
        color: black;
        font-weight: normal;
        font-size: 36px;
        line-height: 45px;
      }
      p {
        margin: 0 20px;
        color: #000;
      }
      button {
        margin-top: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        width: 135px;
        height: 32px;
        border-radius: 16px;
        text-transform: none;
        padding: 6px 12px;
        background: #25F42F;
        color: black;
        &:hover {
          background: #24E229;
        }
      }
    }

    .appview {
      position: absolute;
      right: calc(50% - 120px);
      top: 63.59px;
      background-image: url("../../images/pc/seg-1-app.png"); // 2.78
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: 755px;
      height: 480.5px;
    }

    
    @media (max-width: 1880px) {
      .vocalnet {
        left: calc(50% + 315px + (50% - 315px - 400px) / 2);
      }
      .appview {
        right: calc(50% - 120px + 80px);
      }
      background-position-x: calc(50% - 80px);
    }

    @media (max-width: 1280px) {
      .vocalnet {
        right: 42px;
        left: auto;
      }
      .appview {
        right: calc(42px * 2 + 320px);
      }
      background-position-x: calc(50% - (42px * 2 + 320px));
    }

    @media (max-width: 700px) {
      margin-bottom: 30px;
      & {
        overflow: visible;
        height: 540px;
        min-height: 540px;
        background-image: url("../../images/mb/seg1-line-2.png");
        background-size: contain;
        background-position: bottom center;
      }

      .vocalnet {
        top: 60px;
        left: calc(50% - 268px / 2);
        right: auto;
        width: 268px;

        h2 {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          margin: 0;
        }
      }

      .appview {
        right: calc(50% - 330px / 2);
        top: 312.2px;
        width: 330px;
        height: 210.02px;
      }
    }
  }
  .seg2 {
    position: relative;
    background-color: #000;
    min-height: 570px;
    height: 570px;
    background-image: url("../../images/pc/seg-2-line-bak.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    overflow: hidden;

    .drumnet {
      color: #FFFEFE;
      text-align: center;
      position: absolute;
      top: 72px;
      right: calc(50% + 120px + 260px);
      width: 300px;

      h2 {
        margin: 0 0 32px 0;
        color: #7070F9;
        font-weight: normal;
        font-size: 40px;
        line-height: 48px;
      }
      p {
        margin: 0 20px;
        color: #E7E9EF;
      }
      button {
        margin-top: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        width: 135px;
        height: 32px;
        border-radius: 16px;
        background: linear-gradient(68.19deg, #7087F9 6.03%, #707FF9 21.86%, #7074F9 57.03%, #7070F9 93.96%);
        text-transform: none;
        padding: 6px 12px;
        &:hover {
          background: linear-gradient(35deg, #4646E0 0%, #4646E0 100%);
        }
      }
    }

    .appview {
      position: absolute;
      left: calc(50% - 120px);
      top: 72px;
      background-image: url("../../images/pc/seg-2-app.jpg"); // 2.35
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: 1027.66px;
      height: 436.97px;
    }

    @media (max-width: 1900px) {
      .drumnet {
        right: calc(50% + 120px + (50% - 120px - 400px) / 2);
      }
    }

    @media (max-width: 1024px) {
      .drumnet {
        right: auto;
        left: 0;

        p {
          margin: 0 30px;
        }
      }
      .appview {
        left: 400px;
      }
    }

    @media (max-width: 700px) {
      & {
        min-height: 540px;
        height: 540px;
        overflow: visible;
        background-image: url("../../images/mb/seg2-line-2.png");
        background-size: contain;
        background-position-y: 310px;
      }
      .drumnet {
        top: 60px;
        right: calc(50% - 324px / 2);
        left: auto;
        width: 324px;
        h2 {
          font-size: 32px;
          line-height: 42px;
        }
        p {

        }
      }

      .appview {
        left: calc(50% - 346px / 2);
        top: 334px;
        width: 346px;
        height: 147.12px;
        z-index: 10;
      }
    }
  }

  .seg3 {
    position: relative;
    background-color: #DFDFDF;
    min-height: 570px;
    height: 570px;
    background-image: url("../../images/pc/seg-3-wave.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    overflow: hidden;
    margin-bottom: 100px;
    .custom {
      text-align: center;
      position: absolute;
      top: 72px;
      left: calc(50% + 315px + 153px);
      width: 320px;

      h2 {
        margin: 0 0 36px 0;
        color: #FF6500;
        font-weight: normal;
        font-size: 36px;
        line-height: 45px;
      }
      p {
        margin: 0 20px;
        color: #000;
      }
      button {
        margin-top: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        width: 135px;
        height: 32px;
        border-radius: 16px;
        text-transform: none;
        padding: 6px 12px;
        background: linear-gradient(35deg, #FFA240 0%, #FF651A 100%);
        &:hover {
          background: linear-gradient(35deg, #FF651A 0%, #FF651A 100%);
        }
      }
    }

    .video {
      position: absolute;
      right: calc(50% - 315px);
      top: 68px;
      background-image: url("../../images/pc/video.jpg"); // 2.78
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: 1206.96px;
      height: 433.96px;
    }

    @media (max-width: 1880px) {
      .custom {
        left: calc(50% + 315px + (50% - 315px - 400px) / 2);
      }
      .video {
        right: calc(50% - 315px + 80px);
      }
      background-position-x: calc(50% - 80px);
    }

    @media (max-width: 1280px) {
      .custom {
        right: 42px;
        left: auto;
      }
      .video {
        right: calc(42px * 2 + 320px);
      }
      background-position-x: calc(50% - (42px * 2 + 320px));
    }

    @media (max-width: 700px) {
      margin-bottom: 30px;
      & {
        overflow: visible;
        height: 540px;
        min-height: 540px;
        background-image: url("../../images/mb/seg3-wave-2.png");
        background-size: contain;
        background-position: bottom center;
      }

      .custom {
        top: 60px;
        left: calc(50% - 268px / 2);
        right: auto;
        width: 268px;

        h2 {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          margin: 0;
        }
      }

      .video {
        right: calc(50% - 346px / 2);
        top: 378px;
        width: 346px;
        height: 125px;
      }
    }
  }
}
