
.root {
  background-image: url(../../images/topbg2.png);
  background-position: top center;
  background-repeat: repeat-x;
  background-size: auto 90px;
}

.slogan {
  background-image: url(../../images/slogan.png);
  background-repeat: no-repeat;
  min-height: 90px;
  /* width: 1180px; */
  max-width: 1180px;
  margin: 0 auto;
  background-size: auto 60%;
  background-position-y: 23px;
}

body:global(.webp) .root {
  background-image: url('../../images/topbg2.webp');
 }

 body:global(.webp) .slogan {
  background-image: url('../../images/slogan.webp');
 }
 
@media (max-width: 1189px) {
  .slogan {
    margin: 0 10px;
  }
}

@media (max-width: 500px) {
  .slogan {
    background-size: contain;
  }
}