
@import '../../shared.scss';

.root {
  max-width: 1180px;
  background: #FFFFFF;
  box-shadow: 0px 5px 12px 0px #AAAAAA;
  margin: 0 auto;
  min-height: 380px;
}

// .root {
//   max-width: 1180px;
//   background: #FFFFFF;
//   box-shadow: 0px 5px 12px 0px #AAAAAA;
//   padding: 20px 0;
//   margin: 0 auto;
//   min-height: 320px;

//   & + .root {
//     margin-top: 30px;
//   }

//   .title {
//     // max-width: 1080px;
//     font-family: "Futura Condensed ExtraBold", Futura;
//     font-weight: normal;
//     color: #222222;
//     line-height: 39px;
//     margin: 0px 50px 15px 50px;
//   }

//   .desc {
//     // max-width: 1080px;
//     font-size: 16px;
//     font-family: "Futura Medium", Futura;
//     font-weight: 500;
//     color: #454545;
//     line-height: 32px;
//     margin: 0 50px 0 50px;
//   }

//   .list {
//     display: flex;
//     flex-wrap: wrap;
//     margin: 30px 40px 0 40px;
//   }

//   .card {
//     margin: 0 auto 25px auto;
//   }

//   .cell {
//     width: 20%;

//     @media (max-width: 1150px) {
//       width: 25%;
//     }

//     @media (max-width: 940px) {
//       width: 33.3%;
//     }

//     @media (max-width: 730px) {
//       width: 50%;
//     }

//     @media (max-width: 520px) {
//       width: 100%;
//     }
//   }

//   @media (max-width: 1189px) {
//     margin: 0 10px;
//   }
// }