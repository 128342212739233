@import '../../shared.scss';
@import './base.scss';


.root {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;

    width: 390px;
    // padding-top: 40px;

    @media (max-width: 500px) {
      height: 100vh;
      width: 100vw;
      justify-content: center;
    }

    .logoWrapper {
      text-align: center;
    }

    .head {
      position: relative;
      height: 40px;
      width: 100%;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      @media (max-width: 500px) {
        position: fixed;
        font-size: 36px;
      }  
    }

    .signupTips {
      font-size: 22px;
      font-family: 'Futura Bold', Futura;
      font-weight: bold;
      line-height: 35px;
      margin-top: 32px;
    }

    .field {
      z-index: 2;
      width: 300px;
      color: #333;
    
      .input  {
        @extend %input;
        color: #333;
        input {
          color: #333;
        }
      }
      
      &.fieldEmail {
        margin-top: 42px;
      }
    }

    .error {
      z-index: 2;
      min-height: 42px;
      line-height: 42px;
      width: 300px;
      font-size: 12px;
      text-align: left;
      color: rgba(255, 38, 38, 1);
    }

    .submit {
      @extend %submit;
      z-index: 2;
      margin-top: 15px;
      text-transform: none;
      width: 300px;
      // height: 35px;
      background: $palette-primary-main;
      border-radius: 18px;
    }

    .divider {
      @extend %base-divider;
      z-index: 2;
      color: #333;
    }

    .actions {
      @extend %base-actions;
      margin-top: 30px;
      width: 300px;
      z-index: 2;

      .facebook, .google {
        max-width: 145px;
      }
    }


    .info {
      width: 320px;
      line-height: 30px;
      margin-top: 24px;
      height: 60px;
      font-size: 12px;
      color: #333;
      text-align: center;
      z-index: 2;

      a {
        cursor: pointer;
      }
    }

  }

}