

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000 !important;

  .video {
    //  width="560" height="315"
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) * 0.56);
    max-width: 1200px;
    max-height: 672px;
  }
}