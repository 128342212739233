@import '../../shared.scss';

%base-actions {
  display: flex;
  justify-content: space-between;
  
  %base-social {
    flex: 1;
    height: 40px;
    border-radius: 20px;
    text-transform: none;

    & :global(.MuiButton-startIcon) {
      display: inline-block;
      height: 24px;
    }
  }
  
  .facebook {
    @extend %base-social;
    background: rgb(60, 89, 155);
    color: #FFF;
    // margin-left: 4px;
    &:hover {
      background-color: rgb(42, 62, 108); // 70%
    }
    &>span>svg {
      font-size: 24px
    }
  }
  
  .google {
    @extend %base-social;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    color: #454545;
    // margin-right: 4px;
    &:hover {
      background-color: #DDD;
      color: #000;
    }
  }

  // .google + .facebook {
  //   margin-left: 8px;
  // }
}

%base-divider {
  font-size: 14px;
  margin-top: 34px;
  width: 300px;
  height: 20px;
  color: #F2F2F2;
  align-items: flex-start;
  // display: block;
  &::before, &::after {
    border-top: 1px solid #F2F2F2;;
  }
  & > span {
    height: 20px;
    line-height: 20px;
  }
}

%base-root {
  min-height: 100vh;
  overflow-x: hidden;
  background-image: url('../../images/bg.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto 100%;
  background-color: #FFF;
  display: flex;
  color: #FFF;
  justify-content: center;
  position: relative;
  z-index: 1;

  body:global(.webp) & {
    background-image: url('../../images/bg.webp');
  }
  
  .wrapper {
    position: absolute;
    background-color: rgba(29, 3, 77, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @media (max-width: 1181px) {
    background-position-x: 20vw;
  }

  @media (max-width: 860px) {
    background-position-x: 10vw;
  }
}


%input {
  // color: #D4D4D4;
  @extend %input-base;
  color: #FFF;
  font-size: 12px;
  input {
    color: #FFF;
  }
  & > .notchedOutline {
    border: 1px solid #CCCCCC !important;
  }
}


%submit {
  &:global(.MuiLoadingButton-loading) {
    background-color: $palette-primary-main;
  }

  & :global(.MuiLoadingButton-loadingIndicator) {
    color: #FFF;
  }
}