

.root {
  min-width: 785px;
  @media (max-width: 785px) {
    min-width: 100%;
  }
  :global {
    .MuiSnackbarContent-root {
      background-color: #FFF;
      color: #000;
    }
  }

  .content {
    margin: 0 10px;
  }

  .policy {
    text-decoration: underline;
    margin: 0 5px;
    color: #000;
  }
  .agree {
    margin-left: 8px;
    cursor: pointer;
    color: #FF651A;
  }
}