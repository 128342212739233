@import '../../shared.scss';


.avatar {
  height: 26px;
  width: 26px;
  cursor: pointer;
  border: 2px solid #FFF;
  margin-right: 0;
}

.paper {
  width: 300px;
  border-radius: 0;
  margin-top: 15px;
}

:global(.MuiDrawer-paper).drawerPaper {
  background-color: #F2F2F2;
}

.avatar2 {
  height: 60px;
  width: 60px;
  cursor: pointer;
  border: 2px solid #FFF;
  margin-right: 0;
}

.header {
  height: 90px;
  display: flex;
  flex-direction: row;
  background: rgba(34, 34, 34, 0.75);
  border-bottom: 1px solid $palette-primary-main;

  .headerLeft {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerRight {
    flex: 1;

    .displayName {
      margin: 17px 5px 0 5px;
      height: 31px;
      font-size: 24px;
      font-family: 'Futura Medium', Futura;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 32px;
    }

    .email {
      margin: 3px 5px auto 5px;
      height: 17px;
      font-size: 12px;
      font-family: Futura;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17px;
    }
  }
}

.linkRow {
  height: 40px;
  a {
    padding: 0 24px;
    display: block;
    font-size: 16px;
    font-family: 'Futura Medium', Futura;
    font-weight: 500;
    color: #000000;
    line-height: 40px;
    cursor: pointer;
  }
  & + & {
    border-top: 1px solid #EDEDED;
  }
}
