
.root {
  height: 100%;
  width: 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .pop {
    position: absolute;
    top: 0;
    left: -5px;
    width: 100%;
    height: 180px;
    z-index: 200;
    cursor: pointer;
    width: 36px;
  }

  .popBottom {
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 100%;
    height: 180px;
    z-index: 200;
    cursor: pointer;
    width: 36px;
  }

  .volume {
    font-size: 26px;
    font-family: 'Futura', sans-serif;
    color: rgba(161, 161, 161, 1);
    font-weight: 600;
    line-height: 100%;
    cursor: pointer;
  }

  .bottomVolume {
    @media (max-width: 500px) {
      display: none;
    }
  }

  &:hover .volume {
    color: rgba(255, 255, 255, 1);
  }

  &:hover .bottomVolume {
    color: #FF651A;
  }

  .overlay {
    position: absolute;
    top: 60px;
    height: 120px;
    width: 36px;
    background: #222222;
    border-radius: 0px 0px 4px 4px;
    opacity: 0.34;
  }

  .overlayBottom {
    position: absolute;
    bottom: 58px;
    height: 120px;
    width: 36px;
    background: #222222;
    border-radius: 0px 0px 4px 4px;
    opacity: 0.34;
  }

  .control {
    position: absolute;
    top: 68px;
    left: 3px;
    height: 98px;
    width: 4px;
  }

  .controlBottom {
    position: absolute;
    bottom: 66px;
    left: 3px;
    height: 98px;
    width: 4px;
  }

  .thumb {
    color: #fff;
    width: 10px;
    height: 10px;
    // margin-left: -0.5px;
    // margin-top: -3px;
    &:hover , &:focus, &:active {
      box-shadow: none !important;
    }
  }

  .rail {
    width: 4px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
  }

  .track {
    width: 4px;
    border-radius: 2px;
  }

  .focusVisible {
    box-shadow: none !important;
  }
}