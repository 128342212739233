


footer.root {
  margin: 150px 58px 60px 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1260px) {
    margin: 130px 25px 60px 25px;
  }
  @media (max-width: 630px) {
    flex-direction: column;
    margin: 130px 25px 20px 25px;
  }
  &.mini {
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    margin: 130px 15px 20px 15px;

    .footerLeft {
      flex-direction: column;
    }
  }

  .footerLeft {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    &.white {
      color: white;
    }
    .copyright {
      margin-right: 30px;
    }

    .policies {
      a {
        text-decoration: none;
        color: #999999;
        &.white {
          color: white;
        }

        & + a {
          margin-left: 20px;
          @media (max-width: 630px) {
            margin-left: 12px;
          }
        }
      }
      .faq {
        @media (max-width: 500px) {
          display: none;
        }
      }
    }

    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      .copyright {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    @media (max-width: 630px) {
      font-size: 12px;
      .copyright {
        margin-bottom: 5px;
      }
    }
  }

  .footerRight {
    svg {
      color: #222222;
      cursor: pointer;
      &.white {
        color: white;
      }
    }
    a + a {
      margin-left: 26px;
    }
    @media (max-width: 630px) {
      margin-top: 10px;
      a + a {
        margin-left: 20px;
      }
    }
  }
}
