


.root {
  width: 780px;
  z-index: 173;
  // height: 585px;
  border-radius: 4px;
  box-shadow: 0px 5px 12px 0px rgba(64, 64, 64, 1);
  overflow: hidden;
  background-color: #FFF;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;

  @media (max-width: 780px) {
    width: calc(100vw - 20px);
  }

  @media (max-width: 750px) {
    // height: 480px;
  }

  &.contactUs {
    width: 500px;
    padding: 30px 0;
    height: auto;
    text-align: center;
    @media (max-width: 520px) {
      width: calc(100vw - 20px);
    }
    h3 {
      font-size: 20px;
      // font-weight: 600;
    }
  }

  h2 {
    margin-top: 42px;
    margin-bottom: 0;
    height: 38px;
    font-size: 30px;
    font-family: "Futura Condensed ExtraBold", Futura;
    font-weight: normal;
    color: #222222;
    line-height: 39px;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 20px;
      font-family: "Futura Bold", Futura;
      font-weight: bold;
      color: #181C1F;
      line-height: 27px;
    }
  }

  .desc {
    margin-top: 26px;
    text-align: center;

    // height: 48px;
    font-size: 14px;
    font-family: "Futura Medium", Futura;
    font-weight: 400;
    color: #808080;
    line-height: 24px;

    @media (max-width: 750px) {
      margin-top: 10px;
      span {
        display: block;
        font-size: 20px;
      }
    }
  }

  .plans {
    display: flex;
    margin: 28px auto 0 auto;
    justify-content: space-between;
    width: 656px;
    min-height: 306px;

    @media (max-width: 750px) {
      display: none;
    }

    .plan {
      width: 200px;
      text-align: center;
      
      .planTop {
        height: 20px;
        position: relative;

        .topBg {
          width: 0;
          height: 0;
          border-width: 0 100px 20px 100px;
          border-style: solid;
          border-color: transparent transparent rgba(237, 237, 237, 1);
        }

        .topCr {
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: #FFF;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .planDetail {
        border: 1px solid rgba(237, 237, 237, 1);
        height: 284px;


        h4 {
          height: 21px;
          font-size: 16px;
          font-family: "Futura Medium", Futura;
          font-weight: 500;
          color: #222222;
          line-height: 21px;

          margin: 20px 0 0 0;
        }

        .credits {
          height: 84px;
          font-size: 60px;
          font-family: "Futura Medium", Futura;
          font-weight: 600;
          color: #222222;
          line-height: 84px;
        }

        .tips {
          height: 20px;
          font-size: 14px;
          font-family: "Futura Medium", Futura;
          font-weight: 500;
          color: #808080;
          line-height: 20px;

          margin-top: 10px;
        }

        .price {
          margin-top: 20px;
          height: 28px;
          color: rgba(128, 128, 128, 1);
          font-family: "Futura Medium", Futura;
          font-weight: 500;
          line-height: 28px;
          font-size: 14px;

          .money {
            font-size: 20px;
            color: #222222;
          }
        }

        .pay {
          width: 124px;
          height: 36px;
          font-size: 16px;
          margin-top: 23px;
          font-family: "Futura Medium", Futura;
          text-transform: none;
        }
      }
    }
  }

  .question {
    height: 18px;
    font-size: 12px;
    font-family: question, "Futura Medium", Futura;
    font-weight: 400;
    color: #FF651A;
    line-height: 17px;

    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;

    a {
      cursor: pointer; 
    }
  }

  .mobilePlans {
    display: none;
    @media (max-width: 750px) {
      display: block;
    }
    padding: 0 15px;
    margin-top: 20px;

    .mobilePlan {
      height: 68px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid #E9E9E9;
      display: flex;
      flex-direction: row;

      & + .mobilePlan {
        margin-top: 15px;
      }

      .mobilePlanIconCol {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 68px;
      }
      .mobilePlanIcon {
        width: 28px;
        height: 28px;
        background-color: #000;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: #FFF;
          font-size: 24px;
        }
      }

      .mobilePlanTitleCol {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        .mobilePlanTitle {
          font-size: 14px;
          color: #979797;
          font-family: "Futura Medium", Futura;
          line-height: 20px;
        }

        .mobilePlanCredits {
          color: #21282C;
          line-height: 25px;
          font-size: 18px;
          font-family: 'Futura Bold';
          font-weight: 600;
        }
      }

      .mobilePlanPayCol {
        padding: 10px 20px;

        .mobilePlanPrice {
          color: #979797;
          line-height: 20px;
          font-size: 14px;
          font-family: "Futura Medium", Futura;
        }

        .mobilePlanPay {
          margin-top: 4px;
          height: 23px;
          border-radius: 4px;
          text-transform: none;
        }
      }
    }
  }
}