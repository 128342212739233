@import '../../shared.scss';

$height: 438px;

.root {
  border: 0;
  color: white;
  height: $height;
  padding: 0;
  width: 1180px;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 20px;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJMAAACTCAYAAACK5SsVAAAAAXNSR0IArs4c6QAAAzRJREFUeF7t0gGJAwAQA8HWRv29nPdXHxXQh9dwsBDmBFzIZp/fn9f34RA4IPAk0wFFL/4JkIkIZwTIdIbSIzJx4IwAmc5QekQmDpwRINMZSo/IxIEzAmQ6Q+kRmThwRoBMZyg9IhMHzgiQ6QylR2TiwBkBMp2h9IhMHDgjQKYzlB6RiQNnBMh0htIjMnHgjACZzlB6RCYOnBEg0xlKj8jEgTMCZDpD6RGZOHBGgExnKD0iEwfOCJDpDKVHmUyf3zf6YwTINDZoWYdMJf2xbDKNDVrWIVNJfyybTGODlnXIVNIfyybT2KBlHTKV9MeyyTQ2aFmHTCX9sWwyjQ1a1iFTSX8sm0xjg5Z1yFTSH8sm09igZR0ylfTHssk0NmhZh0wl/bFsMo0NWtYhU0l/LJtMY4OWdchU0h/LJtPYoGUdMpX0x7LJNDZoWYdMJf2xbDKNDVrWIVNJfyybTGODlnXIVNIfyybT2KBlHTKV9MeyyTQ2aFmHTCX9sWwyjQ1a1iFTSX8sm0xjg5Z1yFTSH8sm09igZR0ylfTHssk0NmhZh0wl/bFsMo0NWtYhU0l/LJtMY4OWdchU0h/LJtPYoGUdMpX0x7LJNDZoWYdMJf2xbDKNDVrWIVNJfyybTGODlnXIVNIfyybT2KBlHTKV9MeyyTQ2aFmHTCX9sWwyjQ1a1iFTSX8sm0xjg5Z1yFTSH8sm09igZR0ylfTHssk0NmhZh0wl/bFsMo0NWtYhU0l/LJtMY4OWdchU0h/LJtPYoGUdMpX0x7LJNDZoWYdMJf2xbDKNDVrWIVNJfyybTGODlnXIVNIfyybT2KBlHTKV9MeyyTQ2aFmHTCX9sWwyjQ1a1iFTSX8sm0xjg5Z1yFTSH8sm09igZR0ylfTHssk0NmhZh0wl/bFsMo0NWtYhU0l/LJtMY4OWdchU0h/LJtPYoGUdMpX0x7LJNDZoWYdMJf2xbDKNDVrWIVNJfyybTGODlnXIVNIfyybT2KBlHTKV9MeyyTQ2aFmHTCX9sWwyjQ1a1iFTSX8sm0xjg5Z1yFTSH8sm09igZR0ylfTHssk0NmhZh0wl/bFsMo0NWtYhU0l/LPsPT1GAsWHNm0oAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: 40px 30px;

  @media (max-width: $layout-breakpoint-small) {
    background-position: 56px 30px;
  }

  :global {
    // 保证类名不变
    .control-dots {
      bottom: 15px;
      .dot {
        box-shadow: none;
        border: 2px solid #222222;
        opacity: 1;
        background: inherit;
        &.selected {
          background: #222222
        }
        &:focus {
          outline: none;
        }
      }
    }
    .carousel-slider {
      overflow: visible;
      // width: 1180px;
    }

    .carousel .slider-wrapper {
      overflow: visible;
    }

    .swiper-pagination-bullets.swiper-pagination {
      bottom: 18px;
      .swiper-pagination-bullet {
        box-shadow: none;
        border: 2px solid #222222;
        opacity: 1;
        background: inherit;
        height: 6px;
        width: 6px;
      }
      .swiper-pagination-bullet-active {
        background: #222222
      }
    }
  }

  &:global(.swiper) {
    overflow: visible;
  }

  .page {
    background: inherit;
    border: 0px;
    border-radius: 0px;
    color: white;
    height: $height;
    padding: 0;
  }

  .button {
    border: none;
    width: 40px;
    height: 40px;
    background: #222222;
    border-radius: 28px;
    opacity: 0.19;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: 20px;
      height: 20px;
    }
    z-index: 100;
    cursor: pointer;
    position: absolute;
    top: $height/2-20px;
  }

  .buttonPre {
    left: -40px;
    @media (max-width: $layout-breakpoint-max) {
      left: 10px;
    }
  }

  .buttonNext {
    right: -40px;
    @media (max-width: $layout-breakpoint-max) {
      right: 10px;
    }
  }
  @media (max-width: $layout-breakpoint-max) {
    width: 100%;
  }

  @media (max-width: 500px) {
    height: 300px;
    background-image: none;
    .page {
      height: 300px;
    }
    .button {
      top: 130px;
    }
    :global {
      // 保证类名不变
      .control-dots {
        bottom: 10px;
      }
    }
  }
}

.wrap {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  min-height: 428px;
  @media (max-width: 500px) {
    min-height: 300px;
  }
}

.hidden-left {
  // height: 428px;
  // position: absolute;
  // top: 150px;
  // width: calc((100% - 1180px) / 2);
  // left: 0;
  // z-index: 50;
  // background-color: #F2F2F2;

  // width: calc(100% - (100% - 1180px) / 2);
  // margin-left: calc((100% - 1180px) / 2);
  // overflow: hidden; 
  @media (max-width: $layout-breakpoint-max) {
    display: none;
  }

  height: 428px;
  position: absolute;
  top: 0;
  width: calc((100vw - 1180px) / 2);
  left: calc((1180px - 100vw) / 2);
  background-color: #F2F2F2;
  z-index: 49;
}