

$layout-breakpoint-max: 1280px;
$layout-breakpoint-content: 1180px;
$layout-breakpoint-small: 980px;
$layout-breakpoint-min: 780px;

// 主色
$palette-primary-main: #FF651A;

// 背景
$layout-background-color: #F2F2F2;

$content-width-max: 1180px;




%input-base {
  font-size: 12px;
  input {

    &::-ms-clear, &::-ms-reveal {
      // color: #FFF;
      display: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
     transition-delay: 111111s;
     transition: color 11111s ease-out, background-color 111111s ease-out;
    //  box-shadow: 0 0 0px 1000px rgba(29, 3, 77, 0.84) inset;
    //  -webkit-text-fill-color: #FFF;
  }
}