

.root {
  width: 200px;
  height: 264px;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.card {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.cardBg {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  background-size: 200px 200px;
  filter: blur(10px);
}

.cardTitle {
  width: 150px;
  height: 21px;
  font-size: 16px;
  font-family: "Futura Medium", Futura;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.cardImage {
  width: 178px;
  height: 130px;
  border-radius: 4px;
  background-size: cover;
  background-position: center center;
  position: absolute;
  bottom: 11px;
  left: 11px;
  right: 11px;
}

.cardHover {
  width: 178px;
  height: 130px;
  background: rgba(34, 34, 34, 0.8);
  border-radius: 4px;
  position: absolute;
  bottom: 11px;
  left: 11px;
  right: 11px;
}

.card .cardPlay {
  color: #FF651A;
  background: rgb(255, 255, 255);
  border-radius: 40px;
  font-size: 40px;
  position: absolute;
  bottom: 56px;
  left: 80px;
}

.title {
  height: 24px;
  font-size: 20px;
  font-family: "Futura Medium", Futura;
  font-weight: 500;
  color: #454545;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tag {
  width: 200px;
  height: 20px;
  font-size: 14px;
  font-family: "Futura Medium", Futura;
  font-weight: 500;
  color: #454545;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
}

.free {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 34px;
  height: 23px;
  background-color: #FB7016;
  font-size: 14px;
  font-family: Futura, "Futura Bold";
  font-weight: bold;
  color: #FFFFFF;
  line-height: 23px;
  padding: 0px 7px 0px 8px;
  z-index: 1;
}