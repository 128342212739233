

.root {
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 50px;

    h2 {
      font-size: 30px;
      font-family: "Futura Condensed ExtraBold", Futura;
      font-weight: normal;
      color: #222222;
      line-height: 39px;
      margin: 20px 0;
    }
  }

  &.full {
    .navBtns {
      display: none;
    }

    .body {
      display: flex;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      margin: 0 40px 0px 40px;
      flex: 1;
  
      .item {
        width: 20%;
        margin-bottom: 25px;
  
        @media (max-width: 1150px) {
          width: 25%;
        }
  
        @media (max-width: 940px) {
          width: 33.3%;
        }
  
        @media (max-width: 730px) {
          width: 50%;
        }
  
        @media (max-width: 520px) {
          width: 100%;
        }width: 20%;
  
        @media (max-width: 1150px) {
          width: 25%;
        }
  
        @media (max-width: 940px) {
          width: 33.3%;
        }
  
        @media (max-width: 730px) {
          width: 50%;
        }
  
        @media (max-width: 520px) {
          width: 100%;
        }
      }

      .card {
        margin: 0 auto;
      }
    }
  }


  // --- 单行显示 -----
  &.inline {
    .head {
      .navBtns {
        display: block;
        @media (max-width: 520px) {
            display: none;
        }
      }
      .navBtn {
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        color: #222222;
        margin-top: 38px;
  
        & + .navBtn {
            margin-left: 6px;
        }
        
        svg {
            width: 16px;
        }
  
        &:global(.Mui-disabled) {
            color: rgba(0, 0, 0, 0.26);
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
  
      @media (max-width: 420px) {
        flex-direction: column;
        h2 {
          margin: 10px 0;
        }
        .navBtn {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }

    .body {
      overflow-x: scroll;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      margin: 0 50px;
      scrollbar-width:none;
      -ms-overflow-style:none;
      &::-webkit-scrollbar{
          display:none
      }

      .items {
        display: flex;
        flex-direction: row;

        .item {
          flex-shrink: 0;
          scroll-snap-align: start;
          margin-bottom: 25px;
          & + .item {
            margin-left: 20px;
          }
        }
      }
    }
  }
}